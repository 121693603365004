//React
import React from 'react'

//Gatsby
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

//Actions
import FeatureIcon from '../marketing/featureIcon'
import PreviewVideo from '../elements/previewVideo'
import DividerMid from '../elements/dividerMid'

//Components
import { formatText } from '../../utils/constants'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const formatDescription = (descriptionText) => {
  let workingText = formatText(descriptionText)
  console.log('workingText - == - ')

  console.log(workingText)
  return (
    <React.Fragment>
      {descriptionText.split('\n').map((line, i) => {
        console.log('descriptionText - == - line')
        console.log(line)
        return (
          <span key={i}>
            {formatText(line)}
            <br />
          </span>
        )
      })}
    </React.Fragment>
  )
}

const getVideoSource = (videosource) => {
  switch (videosource) {
    default:
      return 'https://picohealth.s3.amazonaws.com/static/web/picohealth-overview-video.mp4'
  }
}
const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        featureBlank: file(relativePath: { eq: "feature/iphone-frame.png" }) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherLaunchHome: file(
          relativePath: { eq: "feature/publisher-action-manager.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherVoice: file(
          relativePath: { eq: "feature/publisher-voice.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherWeights: file(
          relativePath: { eq: "feature/publisher-canvas-weights.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureRestaurantSolo: file(
          relativePath: { eq: "usecase/app/restaurant-solo.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureRestaurantSoloShort: file(
          relativePath: { eq: "featureperspective/feature-canvas-short.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSearchRealestate: file(
          relativePath: { eq: "feature/publisher-social-search-realestate.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureEnablement: file(
          relativePath: { eq: "feature/publisher-action-manager-detail.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherVideoTrimmer: file(
          relativePath: { eq: "feature/publisher-video-trimmer.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherVideoSharing: file(
          relativePath: { eq: "feature/publisher-video-sharing.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherSharing: file(
          relativePath: { eq: "feature/publisher-image-sharing-realestate.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featurePublisherSharingTravel: file(
          relativePath: { eq: "featureperspective/feature-share-travel.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSocialSearch: file(
          relativePath: { eq: "feature/publisher-social-search.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSocialProfile: file(
          relativePath: { eq: "feature/publisher-social-profile.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSocialProfilePerspective: file(
          relativePath: { eq: "featureperspective/feature-social-profile.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureGettingStarted: file(
          relativePath: { eq: "featureperspective/feature-getting-started.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureEnablementPerspective: file(
          relativePath: { eq: "featureperspective/feature-social-feed.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSocialSearchRealestate: file(
          relativePath: {
            eq: "featureperspective/feature-social-feed-realestate.png"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseTravelSolution: file(
          relativePath: {
            eq: "stock/unsplash/louis-hansel-shotsoflouis-wVoP_Q2Bg_A-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseTravel: file(relativePath: { eq: "usecase/app/travel.png" }) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseRetailSolution: file(
          relativePath: {
            eq: "stock/unsplash/bbh-singapore-HqhSi1Otubo-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseTeamwork: file(
          relativePath: {
            eq: "stock/unsplash/linkedin-sales-navigator-IjkIOe-2fF4-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: WEST) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseDesignAssets: file(
          relativePath: {
            eq: "stock/unsplash/ewan-robertson-fDsCIIGdw9g-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseRestaurantScreenImage: file(
          relativePath: { eq: "usecase/app/restaurant-screenimage.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseRetail: file(relativePath: { eq: "usecase/app/retail.png" }) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseAthleticClubSolution: file(
          relativePath: {
            eq: "stock/unsplash/matthew-lejune-A3EOQ8sP3fY-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseAthleticClub: file(relativePath: { eq: "usecase/app/gym.png" }) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseRestaurant: file(
          relativePath: { eq: "usecase/app/restaurant.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseRestaurantSolution: file(
          relativePath: {
            eq: "stock/unsplash/peter-dawn-mM-L0yx5LcQ-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: CENTER) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseConstruction: file(
          relativePath: {
            eq: "stock/unsplash/daniel-mccullough-HtBlQdxfG9k-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(
              width: 240
              height: 260
              cropFocus: CENTER
              quality: 80
              duotone: { highlight: "#07b2d9", shadow: "#1d1a08" }
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseConstructionSolution: file(
          relativePath: {
            eq: "stock/unsplash/charles-deluvio-Za9oagRJNLM-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(
              width: 240
              height: 260
              cropFocus: CENTER
              quality: 80
              duotone: { highlight: "#40a0b5", shadow: "#1d1a08" }
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseHomehealth: file(
          relativePath: {
            eq: "stock/unsplash/national-cancer-institute-BxXgTQEw1M4-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(
              width: 240
              height: 260
              cropFocus: CENTER
              quality: 80
              duotone: { highlight: "#40a0b5", shadow: "#1d1a08" }
            ) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseHomehealthSolution: file(
          relativePath: { eq: "usecase/app/assistedliving.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industryDirectmarketing: file(
          relativePath: { eq: "usecase/app/directmarketing.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industryRealestate: file(
          relativePath: { eq: "usecase/app/realestate.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industrySalons: file(relativePath: { eq: "usecase/app/salon.png" }) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industryRetail: file(relativePath: { eq: "usecase/app/retail.png" }) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industryPropertymanagement: file(
          relativePath: { eq: "usecase/app/hoa.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        industryHealthcare: file(
          relativePath: { eq: "usecase/app/healthcare.png" }
        ) {
          childImageSharp {
            fixed(width: 350, height: 430, cropFocus: NORTH, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseConference: file(
          relativePath: { eq: "featurevideo/trimmer.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        usecaseExperience: file(
          relativePath: { eq: "usecase/adventure-rally-landing.png" }
        ) {
          childImageSharp {
            fixed(height: 260, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureUploadAdventure: file(
          relativePath: { eq: "featurevideo/upload-adventure.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureTrimmer: file(relativePath: { eq: "featurevideo/trimmer.png" }) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        landingSocial: file(
          relativePath: { eq: "featurevideo/landing-social.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        textStart: file(relativePath: { eq: "featurevideo/text-start.png" }) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleTravel1: file(relativePath: { eq: "usecase/travel.jpg" }) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleSalon1: file(relativePath: { eq: "usecase/salon.jpg" }) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleHealthcare1: file(
          relativePath: { eq: "usecase/healthcare.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleDirectMarketing1: file(
          relativePath: { eq: "usecase/examples/dm-product2.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleDirectMarketing2: file(
          relativePath: { eq: "usecase/examples/dm-product.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleDirectMarketing3: file(
          relativePath: { eq: "usecase/examples/dm-inspiration.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleDirectMarketing4: file(
          relativePath: { eq: "usecase/examples/dm-event.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleDirectMarketing5: file(
          relativePath: { eq: "usecase/examples/dm-recruit.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleRealEstate1: file(
          relativePath: { eq: "usecase/examples/re-listing.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleRealEstate2: file(
          relativePath: { eq: "usecase/examples/re-schools.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleRealEstate3: file(
          relativePath: { eq: "usecase/examples/re-marketanalysis.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleGym1: file(
          relativePath: { eq: "usecase/examples/gym-offer.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleGym2: file(
          relativePath: { eq: "usecase/examples/gym-yoga.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleGym3: file(
          relativePath: { eq: "usecase/examples/gym-groupclass.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        exampleGym4: file(
          relativePath: { eq: "usecase/examples/gym-stretch.jpg" }
        ) {
          childImageSharp {
            fixed(width: 300, height: 300, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        profile1: file(
          relativePath: { eq: "profile/diego-ph-fIq0tET6llw-unsplash.jpg" }
        ) {
          childImageSharp {
            fixed(width: 80, height: 80, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        profile2: file(
          relativePath: { eq: "profile/logan-weaver-p0B7ueoZz8E-unsplash.jpg" }
        ) {
          childImageSharp {
            fixed(width: 80, height: 80, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        profile3: file(
          relativePath: { eq: "profile/meritt-thomas-aoQ4DYZLE_E-unsplash.jpg" }
        ) {
          childImageSharp {
            fixed(width: 80, height: 80, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        profile4: file(
          relativePath: {
            eq: "profile/sharon-pittaway-iMdsjoiftZo-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 80, height: 80, cropFocus: CENTER, quality: 80) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        {props.featureData.map((item, i) => {
          let shiftVal = props.shift ? 1 : 0
          return (
            <>
              {i % 2 !== shiftVal ? (
                <>
                  {item.anchor ? <a id={item.anchor}></a> : null}
                  <div
                    class={`py-3 ${item.bgcolor ? `bg-${item.bgcolor}` : ''} ${
                      item.border ? item.border : ''
                    } ${
                      item.bggradient
                        ? `gradient-callout-${item.bggradient}`
                        : ''
                    }`}
                  >
                    <div
                      class={`container container-alt-callout d-flex align-items-center justify-content-center ${
                        item.bgcolor ? `m-0` : ''
                      }`}
                    >
                      <div class={`row ${!item.bgcolor ? `alt-callout` : ''}`}>
                        <div
                          class={`${
                            item.expandcol
                              ? `col-lg-${item.expandcol}`
                              : 'col-lg-4'
                          } offset-xs-0 offset-lg-2 mb-3 d-flex align-items-center justify-content-center ${
                            item.image ? 'alt-image-sizer' : ''
                          }`}
                        >
                          {item.image ? (
                            <Img
                              alt={item.title}
                              className={`rounded-corners ${
                                item.imageShadow ? 'shadow' : ''
                              } ${
                                item.imageBorderColor
                                  ? `border-1-${item.imageBorderColor}`
                                  : ''
                              }`}
                              fixed={data[item.image].childImageSharp.fixed}
                            />
                          ) : null}
                          {item.videosource ? (
                            <div
                              class="d-flex align-items-center justify-content-center justify-content-lg-end p-4"
                              style={{ display: 'flex' }}
                            >
                              <PreviewVideo
                                videosource={getVideoSource(item.videosource)}
                              />
                            </div>
                          ) : null}
                        </div>

                        <div class="col-lg-6 d-flex">
                          <div
                            class={`feature-${i} ml-0 ml-lg-1 d-flex flex-column align-items-center align-items-lg-start justify-content-center flex-grow-1`}
                          >
                            {item.iconShow ? (
                              <div class="feature-icon-container feature-icon-container-left">
                                {item.iconContext ? (
                                  <FeatureIcon
                                    context={item.iconContext}
                                    color={item.iconColor}
                                    iconfill={item.iconfill}
                                    iconcolor={item.iconcolor}
                                  />
                                ) : (
                                  <FeatureIcon
                                    text={i + 1}
                                    color={item.iconColor}
                                    iconfill={item.iconfill}
                                    iconcolor={item.iconcolor}
                                  />
                                )}
                              </div>
                            ) : null}
                            {item.profilePhoto ? (
                              <div class="feature-icon-container feature-icon-container-left">
                                <Img
                                  alt={item.title}
                                  className={`design-featured-profile-photo`}
                                  fixed={
                                    data[item.profilePhoto].childImageSharp
                                      .fixed
                                  }
                                />
                              </div>
                            ) : null}
                            <div class="d-flex flex-column align-items-center align-items-lg-start justify-content-lg-center text-center text-lg-left">
                              {item.context ? (
                                <div
                                  class={`small text-uppercase font-weight-bold ${
                                    item.contextColor
                                      ? item.contextColor
                                      : 'black-5'
                                  }`}
                                >
                                  {item.context}
                                </div>
                              ) : null}
                              <h2
                                class={`font-weight-${
                                  item.titleWeight ? item.titleWeight : 'light'
                                } ${
                                  item.titleColor ? item.titleColor : 'black-1'
                                }`}
                              >
                                {item.title}
                              </h2>
                              <p
                                class={`lead ${
                                  item.descriptionColor
                                    ? item.descriptionColor
                                    : 'black-1'
                                }`}
                              >
                                {formatDescription(item.description)}
                              </p>{' '}
                              {item.profileDisplayName ? (
                                <a
                                  href={`/profile/${item.profileDisplayName}`}
                                  target="_blank"
                                  class="btn btn-outline-primary"
                                >
                                  View profile{' '}
                                  <FaArrowRight class="btn-arrow" />
                                </a>
                              ) : null}
                              {item.buttonText && item.buttonPath ? (
                                <Link
                                  href={item.buttonPath}
                                  class={`btn ${
                                    item.buttonClass
                                      ? item.buttonClass
                                      : 'btn-outline-primary'
                                  }`}
                                >
                                  {item.buttonText}{' '}
                                  <FaArrowRight class="btn-arrow" />
                                </Link>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {item.lineClass ? (
                    <DividerMid
                      class={item.lineClass ? item.lineClass : 'transparent-1'}
                      line={true}
                      // class="transparent-1"
                      // bottomclass="transparent-4"
                      // flip={false}
                      // lineclass={item.lineClass}
                      // lineoffset={4}
                      // lineflip={true}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  {item.anchor ? <a id={item.anchor}></a> : null}
                  <div
                    class={`py-3 ${item.bgcolor ? `bg-${item.bgcolor}` : ''} ${
                      item.border ? item.border : ''
                    } ${
                      item.bggradient
                        ? `gradient-callout-${item.bggradient}`
                        : ''
                    }  d-flex align-items-center justify-content-center `}
                  >
                    <div
                      class={`container container-alt-callout d-flex align-items-center justify-content-center ${
                        item.bgcolor ? `m-0` : ''
                      }`}
                    >
                      <div
                        class={`row feature-detail-reverse ${
                          !item.bgcolor ? `alt-callout` : ''
                        }`}
                      >
                        <div class="col-lg-6 d-flex">
                          <div
                            class={`feature-${i} mr-0 mr-lg-1 d-flex flex-column align-items-center align-items-lg-end justify-content-center flex-grow-1`}
                          >
                            {item.iconShow ? (
                              <div class="feature-icon-container feature-icon-container-right">
                                {item.iconContext ? (
                                  <FeatureIcon
                                    context={item.iconContext}
                                    color={item.iconColor}
                                    iconfill={item.iconfill}
                                    iconcolor={item.iconcolor}
                                  />
                                ) : (
                                  <FeatureIcon
                                    text={i + 1}
                                    color={item.iconColor}
                                    iconfill={item.iconfill}
                                    iconcolor={item.iconcolor}
                                  />
                                )}
                              </div>
                            ) : null}
                            {item.profilePhoto ? (
                              <div class="feature-icon-container feature-icon-container-left">
                                <Img
                                  alt={item.title}
                                  className={`design-featured-profile-photo`}
                                  fixed={
                                    data[item.profilePhoto].childImageSharp
                                      .fixed
                                  }
                                />
                              </div>
                            ) : null}
                            <div class="d-flex flex-column align-items-center align-items-lg-end justify-content-center text-center text-lg-right">
                              {item.context ? (
                                <div
                                  class={`small text-uppercase font-weight-bold ${
                                    item.contextColor
                                      ? item.contextColor
                                      : 'black-5'
                                  }`}
                                >
                                  {' '}
                                  {item.context}
                                </div>
                              ) : null}
                              <h2
                                class={`font-weight-${
                                  item.titleWeight ? item.titleWeight : 'light'
                                } ${
                                  item.titleColor ? item.titleColor : 'black-1'
                                }`}
                              >
                                {item.title}
                              </h2>
                              <p
                                class={`lead ${
                                  item.descriptionColor
                                    ? item.descriptionColor
                                    : 'black-1'
                                }`}
                              >
                                {formatDescription(item.description)}
                              </p>
                              {item.profileDisplayName ? (
                                <a
                                  href={`/profile/${item.profileDisplayName}`}
                                  target="_blank"
                                  class="btn btn-outline-primary"
                                >
                                  View profile{' '}
                                  <FaArrowRight class="btn-arrow" />
                                </a>
                              ) : null}
                              {item.buttonText && item.buttonPath ? (
                                <Link
                                  href={item.buttonPath}
                                  class={`btn ${
                                    item.buttonClass
                                      ? item.buttonClass
                                      : 'btn-outline-primary'
                                  }`}
                                >
                                  {item.buttonText}{' '}
                                  <FaArrowRight class="btn-arrow" />
                                </Link>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div
                          class={`${
                            item.expandcol
                              ? `col-lg-${item.expandcol}`
                              : 'col-lg-4'
                          } d-flex align-items-center justify-content-center ${
                            item.image ? 'alt-image-sizer' : ''
                          }`}
                        >
                          {item.image ? (
                            <Img
                              alt={item.title}
                              className={`rounded-corners ${
                                item.imageShadow ? 'shadow' : ''
                              } ${
                                item.imageBorderColor
                                  ? `border-1-${item.imageBorderColor}`
                                  : ''
                              }`}
                              fixed={data[item.image].childImageSharp.fixed}
                            />
                          ) : null}
                          {item.videosource ? (
                            <div
                              class="d-flex align-items-center justify-content-center justify-content-lg-end p-4"
                              style={{ display: 'flex' }}
                            >
                              <PreviewVideo videosource="https://storylavamedia.s3.amazonaws.com/static/media/video-1.mp4" />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.lineClass ? (
                    <DividerMid
                      class={item.lineClass ? item.lineClass : 'transparent-1'}
                      // bottomclass={item.bgcolor ? item.bgcolor : 'transparent-1'}
                      line={true}
                      // flip={false}
                      // lineclass={item.lineClass}
                      // lineoffset={4}
                      // lineflip={false}
                    />
                  ) : null}
                </>
              )}
            </>
          )
        })}
      </>
    )}
  />
)

export default Snippet
