import React from "react"

const Snippet = props => (
  <>
    {props.line === true ? (
      <div
        class={`bg-${props.class}`}
        style={{ height: 2, width: "100%" }}
      ></div>
    ) : null}

    {!props.line || props.line === false ? (
      <>
        <div
          class={`diagonal-container ${props.addlclass ? props.addlclass : ""}`}
        >
          <section
            class={`divider diagonal diagonal-${props.class} diagonal-bottom-${
              props.bottomclass
            } ${props.flip ? "diagonal-flip" : ""}`}
          />
        </div>

        <div
          class={
            props.lineflip
              ? `diagonal-line-flip-${props.lineclass}`
              : `diagonal-line-${props.lineclass}`
          }
          style={{
            position: "absolute",
            marginTop: props.lineoffset ? -27 + props.lineoffset : -27,
            zIndex: 10,
            width: "100%",
            height: 30,
          }}
        ></div>
        <div
          class={
            props.lineflip2
              ? `diagonal-line-flip-${props.lineclass2}`
              : `diagonal-line-${props.lineclass2}`
          }
          style={{
            position: "absolute",
            marginTop: props.lineoffset2 ? -27 + props.lineoffset2 : -27,
            zIndex: 10,
            width: "100%",
            height: 30,
          }}
        ></div>
        <div
          class={
            props.lineflip3
              ? `diagonal-line-flip-${props.lineclass3}`
              : `diagonal-line-${props.lineclass3}`
          }
          style={{
            position: "absolute",
            marginTop: props.lineoffset3 ? -27 + props.lineoffset3 : -27,
            zIndex: 10,
            width: "100%",
            height: 30,
          }}
        ></div>
      </>
    ) : null}
  </>
)

export default Snippet
