//React
import React from 'react'
import { Link } from 'gatsby'

//Components
import FeatureIcon from './featureIcon'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const formatDescription = (descriptionText) => (
  <React.Fragment>
    {descriptionText.split('\n').map((line, i) => (
      <span key={i}>
        {line}
        <br />
      </span>
    ))}
  </React.Fragment>
)

const Snippet = (props) => (
  <div
    class={`${
      props.featureData.bgcolor ? `bg-${props.featureData.bgcolor}` : ''
    } ${props.featureData.bggradient ? `${props.featureData.bggradient}` : ''}`}
  >
    <div class="container container-feature-quad">
      <h3
        class={`text-center mb-4 ${
          props.featureData.titlecolor
            ? props.featureData.titlecolor
            : 'white-1'
        }`}
      >
        {props.featureData.title}
      </h3>
      {/* TRIO MODE  */}
      {props.featureData.mode === 'trio' ||
      props.featureData.mode === 'triovertical' ? (
        <div
          class={`row d-flex ${
            props.featureData.mode === 'triovertical'
              ? 'flex-column align-items-center'
              : 'flex-row align-items-stretch'
          }  justify-content-center`}
        >
          {props.featureData.rowdata.map((item, i) =>
            item.rowdata.map((element, j) => (
              <div
                class={`${
                  props.featureData.mode === 'triovertical'
                    ? 'col-lg-6'
                    : 'col-md-3'
                } about-feature bg-${
                  element.colbgcolor ? element.colbgcolor : 'white-1'
                } border-1-${
                  element.colbordercolor ? element.colbordercolor : 'white-1'
                }`}
              >
                <div class="about-feature-body d-flex align-items-center justify-content-start">
                  <div class="my-2">
                    <FeatureIcon
                      context={element.iconname}
                      title="Chatbot"
                      simple
                      color={
                        item.rowbgcolor
                          ? item.rowbgcolor
                          : element.iconcolor
                          ? element.iconcolor
                          : 'white'
                      }
                    />
                  </div>

                  {/* <FaLayerGroup
                          style={{
                            color: "#ffffff",
                            fontSize: 34,
                            opacity: 0.8,
                          }}
                        /> */}
                  {element.title ? (
                    <h4
                      class={`pt-2 text-center ${
                        element.titlecolor ? element.titlecolor : 'white-1'
                      }`}
                    >
                      {element.title}
                    </h4>
                  ) : null}

                  <p
                    class={`p-2 text-center ${
                      element.desccolor ? element.desccolor : 'white-1'
                    }`}
                  >
                    {element.description}{' '}
                  </p>
                  {/* {element.path ? (
                    <Link
                      to={element.path}
                      style={{ marginTop: -10, marginBottom: 10 }}
                      class={`btn btn-sm py-0 px-2 ${
                        item.rowbgcolor
                          ? `btn-${item.rowbgcolor}`
                          : element.iconcolor
                          ? `btn-${element.iconcolor}`
                          : ''
                      }`}
                    >
                      More <FaArrowRight class="btn-arrow" />
                    </Link>
                  ) : null} */}
                </div>
              </div>
            ))
          )}
        </div>
      ) : null}

      {/* QUAD MODE  */}

      {!props.featureData.mode || props.featureData.mode === 'quad'
        ? props.featureData.rowdata.map((item, i) => (
            <div class="row d-flex flex-row align-items-stretch justify-content-center text-center">
              {item.rowdata.map((element, j) => (
                <div
                  class={`col-md-4 about-feature bg-${item.rowbgcolor}-${
                    j + 1
                  }`}
                >
                  <div class="about-feature-body d-flex align-items-center justify-content-start">
                    <div class="my-2">
                      <FeatureIcon
                        context={element.iconname}
                        title="Chatbot"
                        simple
                        color={item.rowbgcolor}
                      />
                    </div>

                    {/* <FaLayerGroup
                  style={{
                    color: "#ffffff",
                    fontSize: 34,
                    opacity: 0.8,
                  }}
                /> */}
                    {element.title ? (
                      <h4 class="pt-2 text-center">{element.title}</h4>
                    ) : null}
                    <p class="p-2">{element.description}</p>
                  </div>
                </div>
              ))}
            </div>
          ))
        : null}
    </div>
  </div>
)

export default Snippet
